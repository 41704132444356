import { Route, Routes, BrowserRouter } from 'react-router-dom';

// Pages
import Home from '../pages/Home';

const AppRouter = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Home />} />
            </Routes>
        </BrowserRouter>
    )
}

export default AppRouter;
