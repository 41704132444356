import React from 'react';
import { Box, Typography } from '@mui/material';
import backImage from "../../assets/images/back.png";
import doctorImage from "../../assets/images/doctor.png";

const getWindowDimensions = () => {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}

const Home = () => {
    const [windowDimensions, setWindowDimensions] = React.useState(getWindowDimensions());
    const [isMobile, setIsMobile] = React.useState(false);

    React.useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    React.useEffect(() => {
        setIsMobile(windowDimensions.width < 500);
    }, [windowDimensions])

    return (
        <Box sx={{
            backgroundImage: `url(${backImage})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: 'cover',
            height: "100vh",
            display: 'flex',
            flexDirection: !isMobile ? 'row' : 'column',
            justifyContent: 'space-between',
            flexWrap: 'wrap'
        }}>
            <Box sx={{
                width: !isMobile ? '50%' : '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}>
                <Typography variant={isMobile ? 'h3' : 'h2'} sx={{ color: 'white', mt: isMobile ? 3 : undefined }}>Coming soon...</Typography>
            </Box>
            <Box sx={{
                width: !isMobile ? '50%' : '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: !isMobile ? 'flex-end' : 'center'
            }}>
                <img src={doctorImage} width={isMobile ? '100%' : undefined} />
            </Box>
        </Box>
    );
}

export default Home;